export const useFormatUrlCdn = () => {
  const addCdnToUrl = (url: string): string => {
    if (!url || !url.includes('.digitaloceanspaces.com')) {
      return url
    }

    const parts = url.split('.digitaloceanspaces.com')

    const modifiedUrl = `${parts[0]}.cdn.digitaloceanspaces.com${parts
      .slice(1)
      .join('.digitaloceanspaces.com')}`

    return modifiedUrl
  }

  return {
    addCdnToUrl
  }
}
